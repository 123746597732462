<template>
    <div class="theory-manage">
        <TheroyDetail :isShow.sync="isShowDetail" :question="detailItem"></TheroyDetail>
        <div class="list-page" v-if="!isShowDetail">
            <div class="search-box">
                <el-input
                    placeholder="请输入内容"
                    v-model="getListParams.search"
                    class="search-input"
                    @keyup.enter.native="getTheoryList"
                >
                    <el-button slot="append" icon="el-icon-search" @click="getTheoryList"></el-button>
                </el-input>
            </div>
            <div class="scroll-content">
                <el-scrollbar style="height: 100%">
                    <TableList :tableColumnConfig="tableConfig" :tableData="packData">
                        <template #default="{ item }">
                            <div>
                                <el-link type="success" :underline="false" @click="getDetail(item)">查看</el-link>
                            </div>
                        </template>
                    </TableList>
                </el-scrollbar>
            </div>
            <div class="paging-box">
                <el-pagination
                    @current-change="pagingChange"
                    :current-page.sync="getListParams.page"
                    :page-size="getListParams.pageSize"
                    layout="prev, pager, next, jumper"
                    :total="totalCount"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import TableList from "components/admin/common/TableList";
import Dialog from "components/admin/common/Dialog";
import TheroyDetail from "components/evaluation/examPaperManage/TheroyDetail";
import { getIdentifyCenterTheoryList, getIdentifyCenterTheoryQuestionDetail } from "@/utils/apis";
export default {
    name: "TheoryManage",
    data() {
        return {
            tableData: [],
            tableConfig: [
                { label: "题文", prop: "title", width: 500 },
                { label: "试卷分类", prop: "category_name" },
                { label: "难度", prop: "difficulty", align: "center" },
                { label: "题型", prop: "type", align: "center" },
                { label: "操作", align: "center" },
            ],
            getListParams: {
                paging: "1",
                page: 1,
                pageSize: 10,
                search: "",
            },
            questionType: ["", "单选题", "多选题", "判断题", "填空题", "问答题"],
            levels: ["", "I", "II", "III", "IV", "V"],
            totalCount: 1,
            isShowDetail: false,
            detailItem: {},
        };
    },
    components: {
        TableList,
        Dialog,
        TheroyDetail,
    },
    computed: {
        /**字典存储 */
        packData() {
            let { tableData } = this;
            return tableData.map((item, index) => ({
                ...item,
                difficulty: this.levels[item.difficulty],
                type: this.questionType[item.type],
            }));
        },
    },
    mounted() {
        this.getTheoryList();
    },
    watch: {
        "getListParams.page"() {
            this.getTheoryList();
        },
    },
    methods: {
        async getTheoryList() {
            let res = await getIdentifyCenterTheoryList(this.getListParams);
            if (res.code == 200) {
                this.tableData = res.data.list ? res.data.list : [];
                this.totalCount = res.data.total <= 0 ? 1 : res.data.total;
            } else {
                this.$message.error(res.msg);
            }
        },
        pagingChange(val) {
            this.getListParams.page = val;
        },
        async getDetail(item) {
            let res = await getIdentifyCenterTheoryQuestionDetail({ theory_topic_id: item.theory_topic_id });
            if (res.code == 200) {
                this.detailItem = res.data;
                res.data.options = JSON.parse(res.data.options);
                res.data.answer = JSON.parse(res.data.answer);
                res.data.analysis = res.data.analysis.replaceAll('"', "");
                this.isShowDetail = true;
            } else {
                this.$message.error(res.msg);
            }
        },
    },
};
</script>

<style scoped lang="scss">
.theory-manage {
    height: 100%;
    padding: 0 20px;
    .list-page {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        .scroll-content {
            flex: 1;
            overflow: hidden;
        }
        .paging-box {
            text-align: center;
            padding: 20px;
        }
        .search-input {
            width: 300px !important;
            margin-bottom: 20px;
            ::v-deep .el-input-group__append {
                padding: 0 8px;
                background: #2dc079;
                .el-button {
                    i {
                        color: #fff;
                        font-size: 16px;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
}
</style>
