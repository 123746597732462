<template>
    <div class="theroy-detail" v-if="Object.keys(question).length > 0 && isShow">
        <div class="back">
            <el-button type="primary" @click="back"> <i class="el-icon-plus" style="margin-right: 5px"></i>返回 </el-button>
        </div>
        <div class="type">{{ questionType[question.type] }}</div>
        <div class="title" v-html="question.title"></div>
        <div class="options" v-if="question.type != 4">
            <div class="options-item" v-for="(item, index) in question.options" :key="index">
                {{ question.type | optionsTag(index) }}、{{ item.name }}
            </div>
        </div>
        <div class="result-box">
            <div class="item result" v-if="resContent" v-html="resContent"></div>
            <div class="item level">难度：{{ levels[question.difficulty] }}</div>
            <div class="answer" v-if="question.analysis != ''" v-html="question.analysis"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TheroyDetail",
    data() {
        return {
            questionType: ["", "单选题", "多选题", "判断题", "填空题", "问答题"],
            levels: ["", "I", "II", "III", "IV", "V"],
        };
    },
    props: {
        question: {
            type: Object,
            default() {
                return {};
            },
        },
        isShow: {
            type: Boolean,
            default: false,
        },
    },
    components: {},
    computed: {
        resContent() {
            let { question } = this;
            let res = question.answer;
            let getChar = (number) => String.fromCodePoint(number);
            //1单选，2多选，3判断，4填空，5问答
            if (question.type == 1) {
                return getChar(65 + res[0]);
            } else if (question.type == 2) {
                return question.answer.map((item, index) => getChar(65 + res[index])).join("、");
            } else if (question.type == 3) {
                return question.options[res[0]].name;
            } else if (question.type == 4) {
                return question.options.map((item) => item.name).join("、");
            } else {
                return question.analysis;
            }
        },
    },
    filters: {
        optionsTag(type, index) {
            return type == 3 ? index + 1 : String.fromCodePoint(65 + index);
        },
    },
    methods: {
        back() {
            this.$emit("update:isShow", false);
        },
    },
};
</script>

<style scoped lang="scss">
.theroy-detail {
    padding: 20px;
    font-size: 16px;
    color: #333;
    .back {
        text-align: end;
    }
    .type {
        font-weight: 700;
    }
    .title {
        margin: 20px;
    }
    .options {
        margin-bottom: 20px;
        .options-item {
            margin-top: 20px;
        }
        .options-item:first-child {
            margin-top: 0;
        }
    }
    .result-box {
        padding: 10px;
        border: 1px solid #aaa;
        .item {
            margin-bottom: 20px;
        }
        .result {
            color: #1cb064;
        }
        .result::before {
            content: "答案：";
        }
        .answer::before {
            content: "解析: ";
        }
    }
}
</style>
